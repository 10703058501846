import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { HashRouter as Router } from "react-router-dom";
import IndexRouter from "./index-router";
// services
import Settings from "./scripts/services/settings";
// import Log from "./scripts/services/log";
// import Glient from "./scripts/services/glient";
import Accounts from "./scripts/services/accounts";
// pages
import ErrorPage from "./scripts/pages/error-page";
// styles
import "./styles/index.less";

// log glient events
// Log.attachToRocWs("glient", Glient);
// add app container

Settings.load((error: Error | null) => {
	const root = createRoot(document.getElementById("container")!);

	if (error) {
		root.render(
			<StrictMode>
				<ErrorPage error={error} title="Error bootstrapping app" />
			</StrictMode>
		);
	} else {
		Accounts.load().then(() => {
			root.render(
				<StrictMode>
					<Router>
						<IndexRouter />
					</Router>
				</StrictMode>
			);
		}).catch(() => {
			console.warn("accounts load failed");
		});
	}
});
