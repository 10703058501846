import { Routes, Route, Navigate } from "react-router-dom";
import Base from "./base";
// services
import Settings from "./scripts/services/settings";
import Providers from "./scripts/services/providers";
// pages
import WelcomePage from "./scripts/pages/welcome-page";
import GatewaysPage from "./scripts/pages/gateways-page";
import UsersPage from "./scripts/pages/users-page";
import StatsGerverPage from "./scripts/pages/stats-gerver-page";
import GLogPage from "./scripts/pages/glog-page";
// import GlientPlaygroundPage from "./scripts/pages/glient-playground-page";
import GupportPlaygroundPage from "./scripts/pages/gupport-playground-page";
import SupportUsersPage from "./scripts/pages/support-users-page";
import TEditorPage from "./scripts/pages/teditor-page";
import CustomTEditorPage from "./scripts/pages/custom-teditor-page";
import BackupPage from "./scripts/pages/backup-page";
import AboutPage from "./scripts/pages/about-page";
import LogPage from "./scripts/pages/log-page";
import SettingsPage from "./scripts/pages/settings-page";
import LocalizationPage from "./scripts/pages/localization-page";
import LoginPage from "./scripts/pages/login-page";
import AccountAddPage from "./scripts/pages/account-add-page";
import GeneralSettingsPage from "./scripts/pages/general-settings-page";

const RootRenderElement = () => {
	const url = new URL(window.location.href);
	if (Providers.redirect(Settings.cluster.providers, url.searchParams)) {
		return null;
	} else {
		url.search = "";
		window.history.pushState(null, "", url.href); // TODO: remove on switch to BrowserRouter
		return <Navigate to="/gateways" />;
	}
};

const IndexRouter = () => (
	<Base injectAdditionalContextProviders={true}>
		<Routes>
			<Route path="/" element={<RootRenderElement />} />
			<Route path="/account/verify" element={<RootRenderElement />} />

			<Route path="/login" element={<LoginPage />} />
			<Route path="/accounts/add/:accountFor" element={<AccountAddPage />} />
			<Route path="/providers">
				{Settings.cluster.providers.map((provider) => (
					<Route key={provider.id} path={provider.id}>
						{Providers.routes(provider)}
					</Route>
				))}
			</Route>

			<Route path="/*">
				<Route path="welcome" element={<WelcomePage />} />
				<Route path="gateways">
					<Route index={true} element={<GatewaysPage />} />
					<Route path=":gatewayId" element={<Navigate replace={true} to="general" />} />
					<Route path=":gatewayId/:tab" element={<GatewaysPage />} />
				</Route>
				<Route path="users">
					<Route index={true} element={<UsersPage />} />
					<Route path=":userId" element={<Navigate replace={true} to="general" />} />
					<Route path=":userId/:tab" element={<UsersPage />} />
				</Route>
				<Route path="stats/gerver" element={<StatsGerverPage />} />
				<Route path="glogs" element={<GLogPage />} />
				{/*<Route path="playground/glient" element={<GlientPlaygroundPage />} />*/}
				<Route path="playground/gupport" element={<GupportPlaygroundPage />} />
				<Route path="support/users">
					<Route index={true} element={<SupportUsersPage />} />
					<Route path=":userId" element={<SupportUsersPage />} />
				</Route>
				<Route path="teditor">
					<Route index={true} element={<TEditorPage />} />
					<Route path=":tableId" element={<TEditorPage />} />
					<Route path="customEditor/:stringKey" element={<CustomTEditorPage />} />
				</Route>
				<Route path="backups" element={<BackupPage />} />
				<Route path="utilities/localization" element={<LocalizationPage />} />
				<Route path="about" element={<AboutPage />} />
				<Route path="general-settings" element={<GeneralSettingsPage />} />
				<Route path="logs" element={<LogPage />} />
				<Route path="settings" element={<SettingsPage />} />
			</Route>

			<Route path="*" element={<Navigate replace={true} to="/" />} />
		</Routes>
	</Base>
);

export default IndexRouter;
